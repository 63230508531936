<mat-tab-group #tabGroup>
    <mat-tab label="Välkommen">
        <ng-template matTabContent>
            <foa-home (handleSwipeLeft)="swipeLeft()"></foa-home>
        </ng-template>
    </mat-tab>
    <mat-tab label="Signerade">
        <ng-template matTabContent>
            <foa-registered (handleSwipeRight)="swipeRight()"></foa-registered>
        </ng-template>
    </mat-tab>
</mat-tab-group>
