import {Inject, Injectable, signal, WritableSignal} from '@angular/core'
import {LOCAL_STORAGE} from '../application/local-storage.provider'
import {ACCESS_TOKEN_NAME} from '../application/types'
import {BehaviorSubject, Observable, of} from 'rxjs'
import {HelperService} from '@jhc/bankid'
import {environment} from '../../environments/environment'
import {HttpClient} from '@angular/common/http'
import {WebSocketSubject} from 'rxjs/webSocket'

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  /**
   * Holds the webSocket
   */
  public subject$: WritableSignal<WebSocketSubject<any> | null> = signal(null)

  constructor(private httpClient: HttpClient,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
  }

  public setToken(accessToken: string): void {
    this.injectedLocalStorage.setItem(ACCESS_TOKEN_NAME, accessToken)
    this.isLoggedIn.next(true)
  }

  public checkLogoutTime(): number {
    const token: any = HelperService.GetTokenPayload(this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME))
    if (token) {
      return token.exp
    } else {
      return 0
    }
  }

  public getCurrentUser() {
    const token: any = HelperService.GetTokenPayload(this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME))
    return token
  }

  public checkLoggedIn(): void {
    this.isLoggedIn.next(!!this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME))
  }

  public logout(): void {
    this.isLoggedIn.next(false)
    this.injectedLocalStorage.removeItem(ACCESS_TOKEN_NAME)
  }

  public createSessionData(photographerSub: string, agreement: string, version: number, comment: string): Observable<any> {
    const url = `${environment.apiUrl}/session`
    const body = {
      photographerSub: photographerSub,
      agreement: agreement,
      version: version,
      sessionComment: comment
    }
    return this.httpClient.put<any>(url, body)
  }

  public sendEmail(/*data: any*/): Observable<boolean> {
    // Send to BE and return when done

    return of(true)
  }
}
