import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {BankIdModule} from '@jhc/bankid'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {MatButtonModule} from '@angular/material/button'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {AuthInterceptor} from './application/auth-interceptor'
import {LOCAL_STORAGE_PROVIDERS} from './application/local-storage.provider'
import {MatInputModule} from '@angular/material/input'
import {MatSelectModule} from '@angular/material/select'
import {HeaderComponent} from './common/header/header.component'
import {FooterComponent} from './common/footer/footer.component'
import {LoginComponent} from './1-login/login.component'
import {MatMenuModule} from '@angular/material/menu'
import {MatIconModule} from '@angular/material/icon'
import {ErrorDialogComponent} from './common/error-dialog/error-dialog.component'
import {MatDialogModule} from '@angular/material/dialog'
import {HomeComponent} from './3-home/home.component'
import {ReactiveFormsModule} from '@angular/forms'
import {MatSnackBarModule} from '@angular/material/snack-bar'
import {RegisteredComponent} from './5-registered/registered.component'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {MatSortModule} from '@angular/material/sort'
import {MatTableModule} from '@angular/material/table'
import {WelcomeComponent} from './2-welcome/welcome.component'
import {MatTabsModule} from '@angular/material/tabs'
import {WebcamModule} from 'ngx-webcam'
import {CameraDialogComponent} from './dialogs/camera-dialog/camera-dialog.component'
import {DetailsDialogComponent} from './dialogs/details-dialog/details-dialog.component'
import {RegretDialogComponent} from './dialogs/regret-dialog/regret-dialog.component'
import {CAMERA_FACTORY} from './application/camera.provider'
import {CameraFactory} from './application/camera-factory.class'
import {QrModule} from '@jhc/qr'
import {SignComponent} from './4-sign/sign.component'
import {StartSignDialogComponent} from './dialogs/start-sign-dialog/start-sign-dialog.component'
import {MatExpansionModule} from '@angular/material/expansion'
import {WINDOW_PROVIDERS} from './application/window.provider'

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    ErrorDialogComponent,
    HomeComponent,
    RegisteredComponent,
    WelcomeComponent,
    CameraDialogComponent,
    DetailsDialogComponent,
    RegretDialogComponent,
    SignComponent,
    StartSignDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BankIdModule,
    BrowserAnimationsModule,
    MatButtonModule,
    HttpClientModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    WebcamModule,
    MatButtonModule,
    QrModule,
    MatExpansionModule
  ],
  providers: [
    {provide: CAMERA_FACTORY, useClass: CameraFactory},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {floatLabel: 'always', hideRequiredMarker: true, appearance: 'outline'}
    },
    LOCAL_STORAGE_PROVIDERS,
    WINDOW_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
