import {Injectable} from '@angular/core'
import {environment} from '../../environments/environment'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  constructor(private httpClient: HttpClient) {
  }

  public getPhotos(): Observable<any> {
    const url = `${environment.apiUrl}/foto`
    return this.httpClient.get<any>(url)
  }

  public deleteItem(itemId: string): Observable<string> {
    const url = `${environment.apiUrl}/foto/${itemId}`
    return this.httpClient.delete<string>(url)
  }

  public signCompleted(accessToken: string): Observable<string> {
    const url = `${environment.apiUrl}/collect`
    return this.httpClient.put<string>(url, accessToken)
  }
}
