<div class="image-container">
    <div *ngIf="image && !accessToken">
        <img alt="photo" src="{{image}}">
    </div>
    <div class="image-info">
        <div *ngIf="!image && !accessToken">
            För att vi ska kunna matcha dig mot bilden/bilderna du är med på behöver vi en separat bild på dig. Bilden
            kommer INTE att publiceras, bara används för internt bruk. <br>
            Så bara tryck på knappen "Ta en selfie" nedan och ta en bild på dig själv.
        </div>
        <div *ngIf="image && !accessToken">
            <p>Toppen! Så här blev bilden. Blev du inte nöjd kan du alltid ta en ny ;-).</p>
            <p>Är du nöjd med bilden kan du enkelt godkänna samtycket genom att klicka på knappen "Starta signering"
                nedan. Då kommer ditt BankID att starta vilket du använder för att godkänna samtycket.</p>
        </div>
        <button (click)="openCam()" *ngIf="!accessToken"
                mat-raised-button>{{!image ? 'Ta en selfie' : 'Ta om selfie'}}</button>
    </div>
</div>
<jhc-bankid
        (accessToken)="signCompleted($event)"
        *ngIf="image && !accessToken"
        [bankIdUrl]="bankIdUrl"
        [userRef]="token"
        [apiUrl]="apiUrl"
></jhc-bankid>

<div *ngIf="!accessToken" class="samtycke-holder">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Här kan du läsa samtycket
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="contract-holder">
                <h3>Samtycke till att använda bilder och filmer för att marknadsföra Helsingborgs stad</h3>
                <h4>Information om bild eller film</h4>
                <h4>Så här behandlar vi dina eller ditt barns personuppgifter</h4>
                <p>Vi lagrar och publicerar bilder och filmer för att informera om eller marknadsföra oss. När vi gör
                    det
                    kan vi också dela med oss av bilder och filmer till stadens olika förvaltningar och bolag, andra
                    kommuner, landsting och föreningar, som vi samarbetar med.</p>
                <p>Till exempel kan bild och film visas i presentationer, annonser, olika typer av samhällsinformation
                    samt
                    digitala kanaler så som sociala medier och webbplatser (exempelvis på dunkerskulturhus.se,
                    fredriksdal.se eller våra sidor på helsingborg.se).</p>
                <p>Kontaktuppgifterna på denna blankett och bild och film på dig eller ditt barn är personuppgifter. För
                    att
                    vi ska få behandla dessa uppgifter krävs samtycke från dig.</p>
                <p>Du kan när som helst ta tillbaka ditt samtycke. Vi kommer då inte längre att behandla personuppgifter
                    om
                    dig eller ditt barn för det ändamål som samtycket gäller för. Uppgifter om dig kommer att arkiveras
                    eller gallras enligt våra rutiner för arkivering.</p>
                <h4>Ditt samtycke</h4>
                <p>Jag samtycker till att ni behandlar mina eller mitt barns personuppgifter på det sätt och för det
                    ändamål
                    som anges ovan.</p>
                <h5>Informationen samlas in av Helsingborg Stad som också är personuppgiftsansvarig. Du kan kontakta oss
                    för
                    att få information om vilka uppgifter som behandlas om dig eller för att få dina uppgifter rättade.
                    Du
                    kan även kontakta vårt dataskyddsombud på dataskyddsombud@helsingborg.se. Du har rätt att klaga
                    till tillsynsmyndigheten Datainspektionen om du tycker att vi behandlar dina personuppgifter på ett
                    felaktigt sätt.</h5>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<div *ngIf="accessToken" class="form-holder">
    <div *ngIf="!allDone">Tack för ditt samtycke!
        Vill du ha en kopia på samtycket kan du fylla i ditt namn och epost nedan.
        <form [formGroup]="contactForm">
            <mat-form-field>
                <mat-label>Namn</mat-label>
                <input formControlName="name" matInput placeholder="För- och efternamn" type="text">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Epost</mat-label>
                <input formControlName="email" matInput placeholder="Ex. eva@gmail.com" type="email">
            </mat-form-field>
        </form>
        <button (click)="sendEmail()" disabled="{{contactForm.invalid}}" mat-raised-button>Skicka</button>
    </div>
    <div *ngIf="allDone">
        Då får du snart ett epost-meddelande med samtycket. Stort tack för att du vill förgylla staden med din närvaro!
    </div>
</div>