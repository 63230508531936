import {inject} from '@angular/core'
import {Router} from '@angular/router'
import {ConfigService} from '../services/config.service'
import {map, Observable} from 'rxjs'
import {LOGIN_ROUTE_PATH} from './data-types'

export const authGuard = (): Observable<boolean | Promise<boolean>> => {

  const router = inject(Router)
  const configService = inject(ConfigService)

  return configService.isLoggedIn.pipe(
    map((loggedIn: boolean) => {
      if (loggedIn) {
        return true
      }
      return router.navigate([LOGIN_ROUTE_PATH])
    })
  )
}
