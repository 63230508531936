<div (swipeleft)="handleSwipeLeft.emit()" class="container">
    <div class="sign-holder">
        <h3>Seal the Deal Foto</h3>
        I fältet nedan kan du göra en anteckning som kan hjälpa dig att identifiera objekten i bilden/bilderna vid ett
        senare tillfälle.
        <mat-form-field class="description">
            <mat-label>Anteckning (kan lämnas tom)</mat-label>
            <textarea
                    [formControl]="comment"
                    cdkAutosizeMaxRows="3"
                    cdkAutosizeMinRows="3"
                    cdkTextareaAutosize
                    matInput
                    placeholder="Ex. Damen i röd basker"></textarea>
        </mat-form-field>
        <div>
            <p>Om du har tagit en bild med din mobil som du vill ha samtycke för trycker du på knappen "Välj bild" och
                väljer bilden i tin telefon för att påbörja godkännandet av samtycket.</p>
            <p>Om du tagit bilder med en annan kamera som du vill ha samtycke för</p>
        </div>
        <div class="button-holder">
            <button (click)="startSign()" mat-raised-button>Starta signering</button>
            <button (click)="startSign()" mat-raised-button>Välj bild</button>
        </div>
    </div>
</div>