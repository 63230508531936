import {Component, OnInit} from '@angular/core'
import {ActivatedRoute, ParamMap} from '@angular/router'
import {ConfigService} from '../services/config.service'
import {environment} from '../../environments/environment'
import {MatDialog} from '@angular/material/dialog'
import {CameraDialogComponent} from '../dialogs/camera-dialog/camera-dialog.component'
import {WebcamImage} from 'ngx-webcam'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {IFileUpload, ImagesService} from '../common/images/services/images.service'
import {PhotoService} from '../services/photo.service'
import {WebSocketSubject} from 'rxjs/webSocket'

@Component({
  selector: 'foa-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss']
})
export class SignComponent implements OnInit {

  public contactForm: FormGroup = new FormGroup({
    name: new FormControl<string>(''),
    email: new FormControl<string>('', Validators.required),
    accessToken: new FormControl<string>('')
  })

  public bankIdUrl = environment.bankIdUrl
  public apiUrl = environment.apiUrl
  public service = environment.service
  public image = ''
  public uploadedImage: IFileUpload = null as any
  public accessToken = ''
  public allDone = false
  public token = ''

  constructor(
    private route: ActivatedRoute,
    private configService: ConfigService,
    private camDialog: MatDialog,
    private imageService: ImagesService,
    private photoService: PhotoService
  ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe({
      next: (params: ParamMap) => {
        if (params.get('token') !== null) {
          this.token = params.get('token') as string
          console.log(this.token)
        }
      }
    })
  }

  private webSocketSubject: WebSocketSubject<any> | null = null

  public signCompleted(event: string) {
    this.accessToken = event
    this.photoService.signCompleted(this.accessToken).subscribe({
      next: value => {

        console.log(value)
        this.webSocketSubject = this.configService.subject$()
        console.log(this.configService.subject$())
        if (this.webSocketSubject) {
          this.webSocketSubject.next({
            type: 'send',
            sessionId: 'sessionId',
            message: value,
            sender: 'sender'
          })
        }
      }
    })
  }

  public openCam() {
    if (this.uploadedImage) {
      this.image = ''
      this.imageService.deleteImage(this.uploadedImage.id as string).subscribe()
    }
    const dialogRef = this.camDialog.open(CameraDialogComponent, {})
    dialogRef.afterClosed().subscribe((result: WebcamImage) => {
      this.image = result.imageAsDataUrl
      const mimeType = this.getMimeTypeFromDataUrl(result.imageAsDataUrl)
      const blob = this.createBlob(result.imageAsDataUrl)
      const image = {
        name: 'selfie',
        contentType: mimeType,
        imageData: blob
      }
      this.imageService.uploadImageData(image).subscribe({
        next: (uploadedImage: IFileUpload) => {
          console.log('uploadedImage afterclosed', uploadedImage)
          this.uploadedImage = uploadedImage
        }
      })
    })
  }

  public createBlob(base64: string) {
    const base64String = base64.split(',')[1]
    const binaryString = atob(base64String)
    const byteArray = new Uint8Array(binaryString.length)

    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i)
    }

    return new Blob([byteArray], {type: 'image/jpeg'})
  }

  public sendEmail(): void {
    this.configService.sendEmail(/*this.contactForm.getRawValue()*/).subscribe({
      next: () => this.allDone = true
    })
  }

  // Get _mimeType from dataUrl
  private getMimeTypeFromDataUrl(dataUrl: string): string {
    const match = dataUrl.match(/^data:(.*?);/)
    if (match && match[1]) {
      return match[1]
    }
    return 'unknown'
  }
}
