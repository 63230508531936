import {Component, inject, Inject, OnInit} from '@angular/core'
import {ConfigService} from '../../services/config.service'
import {first} from 'rxjs'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {environment} from '../../../environments/environment'
import {Session} from '../../3-home/home.component'

@Component({
  selector: 'foa-start-sign-dialog',
  templateUrl: './start-sign-dialog.component.html',
  styleUrls: ['./start-sign-dialog.component.scss']
})
export class StartSignDialogComponent implements OnInit {

  public signUrl = ''
  public token = ''
  public dialogRef: MatDialogRef<StartSignDialogComponent> = inject(MatDialogRef<StartSignDialogComponent>)
  private configService: ConfigService = inject(ConfigService)

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: {
    photographerSub: string
      agreement: string
      version: number
      comment: string }) {
  }

  ngOnInit(): void {
    this.configService.createSessionData(
      this.data.photographerSub,
      this.data.agreement,
      this.data.version,
      this.data.comment
    ).pipe(
      first()
    ).subscribe({
      next: (session: Session) => {
        console.log(session.sessionId)
        this.token = session.sessionId
        this.signUrl = 'https://sealthedeal.se/sign/' + this.token
      }
    })
  }
  protected readonly environment = environment
}
