import {Component} from '@angular/core'
import {MatDialogRef} from '@angular/material/dialog'

@Component({
  selector: 'foa-regret-dialog',
  templateUrl: './regret-dialog.component.html',
  styleUrls: ['./regret-dialog.component.scss']
})
export class RegretDialogComponent {

  constructor(public dialogRef: MatDialogRef<RegretDialogComponent>) {
  }

  public delete(): void {
    this.dialogRef.close(true)
  }
}
