<div matDialogTitle>Borttagning av medgivande</div>
<div class="proof-holder" matDialogContent>
    <p>Detta tar bort all information och går inte att ångra!</p>
    <div class="buttons-holder" matDialogActions>
        <button [mat-dialog-close]="null" color="primary" mat-raised-button>Ångra</button>
        <div style="padding-left: 10px">
            <button (click)="delete()" mat-dialog-close mat-raised-button style="color: red">Ta bort
                medgivande
            </button>
        </div>
    </div>
</div>

