import {Component, EventEmitter, OnInit, Output} from '@angular/core'
import {environment} from '../../environments/environment'
import {FormControl} from '@angular/forms'
import {ConfigService} from '../services/config.service'
import {MatDialog} from '@angular/material/dialog'
import {StartSignDialogComponent} from '../dialogs/start-sign-dialog/start-sign-dialog.component'
import {Router} from '@angular/router'

export interface Session {
  /**
   * Our session id to keep track of several signs
   * */
  sessionId: string

  /**
   * session expiry time
   * */
  expiryTime: number

  /**
   * session comment
   * */
  sessionComment?: string

  /**
   * The personnummer of photographer.
   */
  photographerSub: string

  /**
   * String of agreement
   */
  agreement: string

  /**
   * Don't know right now if we want to use this for some reason
   */
  version: number
}

@Component({
  selector: 'foa-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @Output() handleSwipeLeft = new EventEmitter<Event>()
  public bankIdUrl = environment.apiUrl
  public service = environment.service
  public currentPhotographerSub = ''
  public currentDate = ''
  public comment: FormControl = new FormControl<string>('')
  public signUrl = ''
  public agreement = ''
  public version = 1
  constructor(private router: Router, private configService: ConfigService, private signDialog: MatDialog) {
  }

  public ngOnInit() {
    const user = this.configService.getCurrentUser()
    this.currentPhotographerSub = user?.sub as string

    const today = new Date()
    const year = today.getFullYear()
    const month = (today.getMonth() + 1).toString().padStart(2, '0')
    const day = today.getDate().toString().padStart(2, '0')
    this.currentDate = `${year}-${month}-${day}`
  }

  public startSign(): void {
    this.signDialog.open(StartSignDialogComponent, {
      data:
        {
          photographerSub: this.currentPhotographerSub,
          agreement: this.agreement,
          version: this.version,
          comment: this.comment.value
        }
    }).afterClosed().subscribe({
      next: ((token: string) => {
        if (token) {
          console.log('afterclosed subscribe', token)
          this.router.navigate(['/sign', '/'+token]).then()
        }
        else{
          this.signUrl = ''
          console.log(this.signUrl)
          this.handleSwipeLeft.emit()
        }
      })
    })

  }
}
