<div class="holder">
    <div class="header-holder">
        <div [routerLink]="['/']" class="logo-holder">
            <img alt="foto logo" src="assets/logo.svg">
        </div>
        <div *ngIf="configService.isLoggedIn | async as loggedIn" class="menu-holder">
            <button [matMenuTriggerFor]="menu" mat-button>
                <mat-icon class="icon">menu</mat-icon>
            </button>
            <mat-menu #menu>
                <button (click)="logout()" *ngIf="loggedIn" mat-menu-item>Logga ut</button>
            </mat-menu>
        </div>
    </div>
</div>