import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'

@Component({
  selector: 'foa-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {

  public title = ''

  public message = ''

  private errorMap = {
    404: {
      title: 'Identifiering misslyckad',
      message: 'ID handlingen accepterades inte.'
    },
    429: {
      title: 'För många identifieringar',
      message: 'Du har överskridit din gräns för hur många identifieringar du kan göra.'
    },
    418: {
      title: 'Kaos & Panik!',
      message: 'Tjänsten är just nu så populär att vi inte kan erbjuda fler identifieringar för tillfället.'
    },
    500: {
      title: 'Problem!',
      message: 'Något gick fel hos hos, vi kollar på det.'
    },
    403: {
      title: 'Fel kod!',
      message: 'Detta skall inte finnas'
    },
    401: {
      title: 'Utloggad',
      message: 'Du måste först identifiera dig med BankID'
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { status: 401 | 403 | 418 | 500 | 429 | 404 }
  ) {
    this.title = this.errorMap[data.status].title
  }
}
