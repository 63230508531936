import {Component, ViewChild} from '@angular/core'

@Component({
  selector: 'foa-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {
  @ViewChild('tabGroup') tabGroup: any

  public swipeLeft() {
    this.tabGroup.selectedIndex += 1 // Switch tab to the right
  }

  public swipeRight() {
    this.tabGroup.selectedIndex -= 1 // Switch tab to the left
  }
}
